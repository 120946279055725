export const STATUS_ASSOCIADO_ATIVO = 1;
export const STATUS_ASSOCIADO_INATIVO = 0;
export const STATUS_ASSOCIADO_IRREGULAR = 2;

export const TIPO_CAMPANHA_MENSAL = 1;
export const TIPO_CAMPANHA_TEMPORADA = 2;

export const TIPO_CAMPANHAS = {
  [TIPO_CAMPANHA_MENSAL]: 'mensal',
  [TIPO_CAMPANHA_TEMPORADA]: 'temporada',
};

export const STATUS_ASSOCIADO = {
  [STATUS_ASSOCIADO_ATIVO]: {
    text: 'Ativo',
    color: 'badge.ativo',
  },
  [STATUS_ASSOCIADO_INATIVO]: {
    text: 'Inativo',
    color: 'badge.inativo',
  },
  [STATUS_ASSOCIADO_IRREGULAR]: {
    text: 'Irregular',
    color: 'badge.irregular',
  },
};

export const STATUS_ENDERECO_ATIVO = true;
export const STATUS_ENDERECO_INATIVO = false;

export const STATUS_ENDERECO = {
  [STATUS_ENDERECO_ATIVO]: {
    text: 'Ativo',
    color: 'badge.ativo',
  },
  [STATUS_ENDERECO_INATIVO]: {
    text: 'Inativo',
    color: 'badge.inativo',
  },
};

export const STATUS_TELEFONE_ATIVO = true;
export const STATUS_TELEFONE_INATIVO = false;

export const STATUS_TELEFONE = {
  [STATUS_TELEFONE_ATIVO]: {
    text: 'Ativo',
    color: 'badge.ativo',
  },
  [STATUS_TELEFONE_INATIVO]: {
    text: 'Inativo',
    color: 'badge.inativo',
  },
};

export const STATUS_DEPENDENTE_ATIVO = true;
export const STATUS_DEPENDENTE_INATIVO = false;

export const TIPO_APROVACAO_CADASTRO = 0;
export const TIPO_APROVACAO_DEPENDENTE = 1;
export const TIPO_APROVACAO_TROCA_UNIDADE = 2;

export const STATUS_APROVACAO_PENDENTE = 0;
export const STATUS_APROVACAO_APROVADO = 1;
export const STATUS_APROVACAO_REPROVADO = 2;

export const STATUS_DEPENDENTE = {
  [STATUS_DEPENDENTE_ATIVO]: {
    text: 'Ativo',
    color: 'badge.ativo',
  },
  [STATUS_DEPENDENTE_INATIVO]: {
    text: 'Inativo',
    color: 'badge.inativo',
  },
};

export const MASCULINO = 'M';
export const FEMININO = 'F';
export const OUTRO = 'O';

export const SEXO = {
  [MASCULINO]: 'Masculino',
  [FEMININO]: 'Feminino',
  [OUTRO]: 'Outro',
};

export const TIPO_UNIDADE_APARTAMENTO = 0;
export const TIPO_UNIDADE_CASA = 1;
export const TIPO_UNIDADE_KITNET = 2;
export const TIPO_UNIDADE_CADEIRANTE = 3;

export const TIPO_UNIDADE_LABEL = {
  [TIPO_UNIDADE_APARTAMENTO]: 'Apartamento',
  [TIPO_UNIDADE_CASA]: 'Casa',
  [TIPO_UNIDADE_KITNET]: 'Kitnet',
  [TIPO_UNIDADE_CADEIRANTE]: 'Cadeirante',
};

export const TIPOS_UNIDADES = [
  {
    label: 'Apartamento',
    value: TIPO_UNIDADE_APARTAMENTO,
  },
  {
    label: 'Casa',
    value: TIPO_UNIDADE_CASA,
  },
  {
    label: 'Kitnet',
    value: TIPO_UNIDADE_KITNET,
  },
  {
    label: 'Cadeirante',
    value: TIPO_UNIDADE_CADEIRANTE,
  },
];

export const STATUS_SORTEIO_INICIADO = 0;
export const STATUS_SORTEIO_EM_DESENVOLVIMENTO = 1;
export const STATUS_SORTEIO_INSCRICOES_ENCERRADAS = 2;
export const STATUS_SORTEIO_FINALIZADO = 3;
export const STATUS_SORTEIO_PUBLICADO = 4;

export const STATUS_SORTEIO = [
  { id: STATUS_SORTEIO_INICIADO, label: 'Iniciado' },
  { id: STATUS_SORTEIO_EM_DESENVOLVIMENTO, label: 'Em desenvolvimento' },
  { id: STATUS_SORTEIO_PUBLICADO, label: 'Publicado' },
  { id: STATUS_SORTEIO_INSCRICOES_ENCERRADAS, label: 'Inscrições encerradas' },
  { id: STATUS_SORTEIO_FINALIZADO, label: 'Finalizado' },
];

export const STATUS_USER = [
  { id: true, label: 'Ativo' },
  { id: false, label: 'Inativo' },
];

export const STATUS_SORTEIO_SELECTOR = {
  [STATUS_SORTEIO_INICIADO]: 'Iniciado',
  [STATUS_SORTEIO_EM_DESENVOLVIMENTO]: 'Em desenvolvimento',
  [STATUS_SORTEIO_PUBLICADO]: 'Publicado',
  [STATUS_SORTEIO_INSCRICOES_ENCERRADAS]: 'Inscrições encerradas',
  [STATUS_SORTEIO_FINALIZADO]: 'Finalizado',
};

export const STATUS_RESULTADO_INSCRITO = 0;
export const STATUS_RESULTADO_GANHOU = 1;
export const STATUS_RESULTADO_NAO_CONTEMPLADO = 2;
export const STATUS_RESULTADO_SUPLENTE = 3;

export const STATUS_RESULTADO = {
  [STATUS_RESULTADO_INSCRITO]: 'Inscrito',
  [STATUS_RESULTADO_GANHOU]: 'Contemplado',
  [STATUS_RESULTADO_SUPLENTE]: 'Suplente',
  [STATUS_RESULTADO_NAO_CONTEMPLADO]: 'Não contemplado',
};

export const STATUS_RESULTADO_COLOR = {
  [STATUS_RESULTADO_GANHOU]: '#4AAC48',
  [STATUS_RESULTADO_SUPLENTE]: '#A37FD2',
  [STATUS_RESULTADO_NAO_CONTEMPLADO]: '#E53E3E',
};

export const STATUS_RESULTADO_OPTIONS = [
  { id: STATUS_RESULTADO_GANHOU, label: 'Contemplado' },
  { id: STATUS_RESULTADO_SUPLENTE, label: 'Suplente' },
  { id: STATUS_RESULTADO_NAO_CONTEMPLADO, label: 'Não contemplado' },
];

export const ADMIN_PROFILE = 'Administrador';
export const REGISTER_PROFILE = 'Cadastro';
export const DEFAULT_PROFILE = 'Associado';
export const REPRESENTANTE_PROFILE = 'Representante';
export const CADASTRO_PROFILE = 'Cadastro';
export const COLONIA_PROFILE = 'Atendimento Colônia';
export const GERENTE_PROFILE = 'Gerente Colônia';

export const ADMIN_PROFILE_ID = 1;
export const DEFAULT_PROFILE_ID = 2;
export const REPRESENTANTE_PROFILE_ID = 3;
export const CADASTRO_PROFILE_ID = 4;
export const COLONIA_PROFILE_ID = 5;
export const GERENTE_PROFILE_ID = 6;

export const CAMPANHA_INICIADA = 0;
export const CAMPANHA_EM_ANDAMENTO = 1;
export const CAMPANHA_ENCERRADA = 2;

export const STATUS_CAMPANHA = [
  { id: 0, label: 'Iniciada' },
  { id: 1, label: 'Em andamento' },
  { id: 2, label: 'Encerrada' },
];

export const AREA_ADMINISTRATIVA = 0;
export const AREA_DO_ASSOCIADO = 1;

export const AREA_INSCRICAO = [
  {
    label: 'Área Administrativa',
    value: AREA_ADMINISTRATIVA,
  },
  {
    label: 'Área do Associado',
    value: AREA_DO_ASSOCIADO,
  },
];

export const ORIGEM_INSCRICAO_ASSOCIADO = 0;
export const ORIGEM_INSCRICAO_ADMINISTRATIVO = 1;

export const months = [
  {
    name: 'JAN',
  },
  {
    name: 'FEV',
  },
  {
    name: 'MAR',
  },
  {
    name: 'ABR',
  },
  {
    name: 'MAI',
  },
  {
    name: 'JUN',
  },
  {
    name: 'JUL',
  },
  {
    name: 'AGO',
  },
  {
    name: 'SET',
  },
  {
    name: 'OUT',
  },
  {
    name: 'NOV',
  },
  {
    name: 'DEZ',
  },
];

export const TOTALS_INSCRITOS = 0;
export const TOTALS_SORTEADOS = 1;
export const TOTALS_SUPLENTES = 2;

export const pieChartDataPorSexo = [
  {
    name: 'Feminino',
    y: 20,
  },
  {
    name: 'Masculino',
    y: 80,
  },
];

export const pieChartDataPorPostoGraduacao = [
  {
    name: 'Soldado',
    y: 86,
  },
  {
    name: 'Oficial',
    y: 10,
  },
  {
    name: 'Coronel',
    y: 2,
  },
  {
    name: 'Cabo',
    y: 2,
  },
];

export const pieChartDataPorFaixaEtaria = [
  {
    name: '18-25',
    y: 73,
  },
  {
    name: '25-35',
    y: 20,
  },
  {
    name: '35-45',
    y: 7,
  },
];

export const TOTALS_CHART_FILTERS = [
  {
    id: TOTALS_INSCRITOS,
    label: 'Inscritos',
  },
  {
    id: TOTALS_SORTEADOS,
    label: 'Sorteados',
  },
  {
    id: TOTALS_SUPLENTES,
    label: 'Suplentes',
  },
];

export const INSCRICOES_POR_SEXO = 0;
export const INSCRICOES_POR_POSTO_GRADUACAO = 1;
export const INSCRICOES_POR_FAIXA_ETARIA = 2;

export const INSCRICOES_CHART_FILTERS = [
  {
    id: INSCRICOES_POR_SEXO,
    label: 'Por sexo',
  },
  {
    id: INSCRICOES_POR_POSTO_GRADUACAO,
    label: 'Por posto/graduação',
  },
  {
    id: INSCRICOES_POR_FAIXA_ETARIA,
    label: 'Por faixa etária',
  },
];

export const CUSTOMER_STATUS_NORMAL = 0;
export const CUSTOMER_STATUS_CREDITO_BLOQUEADO = 1;
export const CUSTOMER_STATUS_LIMITE_ATINGIDO = 3;
export const CUSTOMER_STATUS_INADIMPLENTE = 4;
export const CUSTOMER_STATUS_LIMITE_ATINGIDO_INADIMPLENTE = 5;
export const CUSTOMER_STATUS_ENCERROU_ATIVIDADES = 6;
export const CUSTOMER_STATUS_DATA_LIMITE_ATINGIDA = 7;
export const CUSTOMER_STATUS_CADASTRO_INATIVO = 8;
export const CUSTOMER_STATUS_CADASTRO_BLOQUEADO = 9;
export const CUSTOMER_STATUS_SUPORTE_BLOQUEADO = 'S';

export const TEXTO_ASSOCIADO_REGULAR = 'SÓCIO';
export const TEXTO_ASSOCIADO_NAOSOCIO = 'NÃO SÓCIO';
export const TEXTO_ASSOCIADO_IRREGULAR = 'SÓCIO IRREGULAR';
export const TEXTO_REGULAR = 'SITUAÇÃO REGULAR';
export const TEXTO_IRREGULAR =
  'SITUAÇÃO IRREGULAR - Entre em contato com o departamento de cadastro';

export const GRAU_DEPENDENTE = [
  {
    label: 'Pais',
    value: 3,
  },
  {
    label: 'Esposa(o)',
    value: 6,
  },
  {
    label: 'Filho(a) menor 21 anos',
    value: 10,
  },
  {
    label: 'Filho(a) maior 21 anos incapaz',
    value: 12,
  },
  {
    label: 'Neto(a)',
    value: 13,
  },
  {
    label: 'Filho(a) Universitario(a)',
    value: 14,
  },
  {
    label: 'Companheiro(a)',
    value: 16,
  },
  {
    label: 'Menor Tutelado reconhecido pela legislação',
    value: 21,
  },
];
export const PERMISSION_MENU_DASHBOARD = 'Dashboard';

export const PERMISSION_ADICIONAR_RESERVAS_SUBPERIODO =
  'Adicionar reservas do sub-periodo';
export const PERMISSION_EXCLUIR_SUBPERIODO = 'Excluir sub-periodo';
export const PERMISSION_RESERVAS_SUBPERIODO =
  'Listagem reservas do sub-periodo';
export const PERMISSION_CRIAR_SUBPERIODO = 'Criar sub-periodo';
export const PERMISSION_REMOVER_UNIDADES_SUB_PERIODO =
  'Remover unidades sub-periodo';

export const PERMISSION_LISTAGEM_SUBPERIODO =
  'Listagem dos sub-periodos do periodo';
export const PERMISSION_LISTAGEM_ASSOCIADO =
  'Listagem de inscritos por periodo do sorteio';
export const PERMISSION_RESERVAR_UNIDADE = 'Reservar unidade';
export const PERMISSION_DETALHE_SUBPERIODO =
  'Detalhamento do sub-periodo do periodo';
export const PERMISSION_DETALHE_ASSOCIADO = 'Detalhamento do associados';
export const PERMISSION_EDITAR_FOTO_DEPENDENTE = 'Atualizar foto dependente';
export const PERMISSION_REMOVER_FOTO_DEPENDENTE = 'Remover foto dependente';

export const PERMISSION_REMOVER_PESO_CATEGORIA =
  'Remover peso caregoria selecionado';
export const PERMISSION_REMOVER_PESO_POSTO =
  'Remover peso posto e graduação selecionado';

export const PERMISSION_LISTAGEM_PESOS = 'Listagem pesos sorteio';

export const PERMISSION_ATUALIZAR_PODEM_PARTICIPAR =
  'Atualizar podem participar do sorteio';
export const PERMISSION_EDITAR_PESOS_POSTOS_GRADUACAO =
  'Atualizar peso posto e graduação';
export const PERMISSION_EDITAR_PESOS_CATEGORIA = 'Atualizar peso categoria';
export const PERMISSION_EDITAR_PERIODO = 'Editar periodo do sorteio';
export const PERMISSION_DETALHE_PERIODO = 'Detalhe do periodos do sorteio';
export const PERMISSION_EXCLUIR_ASSOCIADO_PERIODO =
  'Excluir associado do periodo do sorteio';

export const PERMISSION_CARREGAR_PADRAO_POSTO =
  'Carregar pesos padrões posto e graduação';

export const PERMISSION_CARREGAR_PADRAO_PESOS =
  'Carregar pesos padrões caregoria';

export const PERMISSION_MENU_ASSOCIADOS = 'Listagem de associados';
export const PERMISSION_EDITAR_ASSOCIADO = 'Editar associados';
export const PERMISSION_EDITAR_FOTO_ASSOCIADO = 'Atualizar foto do associados';
export const PERMISSION_REMOVER_FOTO_ASSOCIADO = 'Remover foto do associados';
export const PERMISSION_ABA_ADMINISTRACAO_ASSOCIADO = 'Associado administração';

export const PERMISSION_BAIXAR_ASSOCIADO_SUPLENTES =
  'Baixar associados suplentes';
export const PERMISSION_BAIXAR_ASSOCIADO_CONTEMPLADOS =
  'Baixar associados contemplados';
export const PERMISSION_BAIXAR_ASSOCIADO_RESULTADOS =
  'Baixar resultado associados';

export const PERMISSION_CANCELAR_INSCRICAO = 'Cancelar inscrições';
export const PERMISSION_DOWNLOAD_EXCEL_INSCRICOES =
  'Download excel de inscrições';

export const PERMISSION_LISTAGEM_SORTEIO_CAMPANHA =
  'Listagem de sorteio por campanha';

export const PERMISSION_PODEM_PARTICIPAR_SORTEIO =
  'Listagem podem participar do sorteio';

export const PERMISSION_MENU_CATEGORIAS = 'Listagem de categorias';
export const PERMISSION_EDITAR_CATEGORIA = 'Editar categorias';
export const PERMISSION_EXCLUIR_CATEGORIA = 'Excluir categorias';
export const PERMISSION_CRIAR_CATEGORIAS = 'Criar categoria';

export const PERMISSION_MENU_USUARIOS = 'Listagem de usuarios';
export const PERMISSION_CRIAR_USUARIOS = 'Criar usuarios';
export const PERMISSION_EDITAR_USUARIO = 'Editar usuarios';
export const PERMISSION_EXCLUIR_USUARIO = 'Excluir usuarios';

export const PERMISSION_MENU_COLONIAS = 'Listagem colonias';
export const PERMISSION_EDITAR_COLONIAS = 'Editar colonias';
export const PERMISSION_CRIAR_COLONIAS = 'Criar colonias';
export const PERMISSION_EXCLUIR_COLONIAS = 'Excluir colonias';
export const PERMISSION_DETALHE_COLONIA = 'Detalhamento da colonias';

export const PERMISSION_CRIAR_BLOCO = 'Criar bloco';
export const PERMISSION_EXCLUIR_BLOCO = 'Excluir bloco';
export const PERMISSION_EDITAR_BLOCO = 'Editar bloco';
export const PERMISSION_ADICIONAR_UNIDADES_BLOCO = 'Criar unidades do bloco';
export const PERMISSION_EDITAR_UNIDADES_BLOCO = 'Editar unidades do bloco';
export const PERMISSION_EXCLUIR_UNIDADES_BLOCO = 'Excluir unidades do bloco';
export const PERMISSION_LISTAGEM_RESERVAS_BLOCO =
  'Listagem de reservas do bloco';
export const PERMISSION_EDITAR_RESERVA_BLOCO = 'Editar reserva do bloco';
export const PERMISSION_EXCLUIR_RESERVA_BLOCO = 'Excluir reserva do bloco';
export const PERMISSION_LISTAGEM_UNIDADES_BLOCO = 'Listagem unidades do bloco';
export const PERMISSION_CRIAR_RESERVA_BLOCO = 'Criar reserva do bloco';
export const PERMISSION_LISTAGEM_BLOCOS = 'Listagem dos blocos';

export const PERMISSION_NOVO_SORTEIO_CAMPANHA = 'Novo sorteio por campanha';
export const PERMISSION_CRIAR_CAMPANHA = 'Criar campanha';
export const PERMISSION_EDITAR_CAMPANHA = 'Editar campanha';
export const PERMISSION_DETALHE_CAMPANHA = 'Detalhamento de campanha';
export const PERMISSION_DETALHAMENTO_SORTEIO_CAMPANHA =
  'Detalhamento de sorteio por campanha';

export const PERMISSION_DETALHE_CADASTRO = 'Visualizar Cadastro';
export const PERMISSION_FORCAR_ATUALIZACAO_CADASTRO =
  'Forçar Atualização do associado - Gerenciar cadastro';
export const PERMISSION_FORCAR_RESET_PASSWORD_CADASTRO =
  'Forçar reset password associado - Gerenciar cadastro';
export const PERMISSION_CONVERSAR_CADASTRO = 'Conversar Whatsapp';
export const PERMISSION_EDITAR_CADASTRO = 'Editar Cadastro - Dados basicos';
export const PERMISSION_ADICIONAR_DEPENDENTE_ASSOCIADO =
  'Criar dependente do associado';
export const PERMISSION_EDITAR_DEPENDENTE_ASSOCIADO =
  'Editar dependete do associado';
export const PERMISSION_EXCLUIR_DEPENDENTE_ASSOCIADO =
  'Excluir dependente do associado';
export const PERMISSION_IMPRIMIR_CARTEIRINHA = 'Imprimir carteirinha associado';

export const PERMISSION_DETALHE_APROVACAO = 'Visualizar detalhe da aprovação';

export const PERMISSION_EDITAR_POSTO_GRADUACAO = 'Editar posto e graduação';
export const PERMISSION_EXCLUIR_POSTO_GRADUACAO = 'Excluir posto e graduação';

export const PERMISSION_MENU_SORTEIOS = 'Listagem de sorteios';
export const PERMISSION_DETALHAMENTO_SORTEIO = 'Detalhamento do sorteio';
export const PERMISSION_VISUALIZAR_RESULTADO_SORTEIO_RECEM_SORTEADO =
  'Visualizar detalhes do resultado do sorteio';
export const PERMISSION_EXCLUIR_RESULTADO_SORTEIO_RECEM_SORTEADO =
  'Excluir resultado do sorteio recem sorteado';
export const PERMISSION_PUBLICAR_RESULTADO_SORTEIO =
  'Publicar resultado do sorteio';
export const PERMISSION_RESORTERA_SORTEIO = 'Resortear sorteio recem sorteado';

export const PERMISSION_EDITAR_SORTEIO = 'Editar sorteios';
export const PERMISSION_CRIAR_SORTEIO = 'Criar sorteio';
export const PERMISSION_EXCLUIR_SORTEIO = 'Excluir sorteios';
export const PERMISSION_EXIBIR_RESULTADO_SORTEIO_ONLINE = 'Resultado Live';
export const PERMISSION_RESULTADOS_SORTEIO =
  'Visualizar detalhes do resultado do sorteio';
export const PERMISSION_SORTEAR_SORTEIO = 'Sortear sorteio';
export const PERMISSION_LISTAGEM_PERIODOS = 'Listagem de periodos do sorteio';
export const PERMISSION_LISTAGEM_PODEM_PARTICIPAR =
  'Listagem podem participar do sorteio';
export const PERMISSION_LISTAGEM_PESOS_SORTEIO = 'Listagem pesos sorteio';
export const PERMISSION_PESOS_DEFAULT_CATEGORIAS =
  'Carregar pesos padrões caregoria';
export const PERMISSION_PESOS_DEFAULT_POSTO_GRADUACAO =
  'Carregar pesos padrões posto e graduação';

export const PERMISSION_DETALHAMENTO_PERIODO_SORTEIO =
  'Detalhamento dos periodos do sorteio';
export const PERMISSION_EXCLUIR_PERIODO_SORTEIO = 'Excluir periodos do sorteio';
export const PERMISSION_EDITAR_PERIODO_SORTEIO = 'Editar periodo do sorteio';
export const PERMISSION_CRIAR_PERIODO_SORTEIO = 'Criar periodos do sorteio';
export const PERMISSION_LISTAGEM_INSCRICOES_SORTEIO =
  'Listagem inscrições do sorteio';
export const PERMISSION_CRIAR_INSCRICAO_SORTEIO = 'Criar inscrição no sorteio';
export const PERMISSION_DOWNLOAD_RECIBO_INSCRICAO =
  'Download do recibo do sorteio';
export const PERMISSION_EXCLUIR_INSCRICAO = 'Excluir inscrição no sorteio';
export const PERMISSION_LISTAGEM_INSCRICOES_POR_PERIODO_SORTEIO =
  'Listagem de inscritos por periodo do sorteio';

export const PERMISSION_LISTAGEM_DATA_OCUPACAO_POR_PERIODO_SORTEIO =
  'Listagem dos sub-periodos do periodo';
export const PERMISSION_CRIAR_SUB_PERIODOS = 'Criar sub-periodo';
export const PERMISSION_DETALHAMENTO_SUB_PERIODOS =
  'Detalhamento do sub-periodo';
export const PERMISSION_EDITAR_SUB_PERIODO_SORTEIO = 'Editar sub-periodo';
export const PERMISSION_LISTAGEM_UNIDADES_SUB_PERIODO =
  'Listagem unidades dos sub-periodo';
export const PERMISSION_ADICIONAR_UNIDADES_SUB_PERIODO =
  'Adicionar unidades ao sub-periodo';

export const PERMISSION_LISTAGEM_RESERVAS_SUB_PERIODO =
  'Listagem reservas do sub-periodo';
export const PERMISSION_ADICIONAR_RESERVAS_SUB_PERIODO =
  'Adicionar reservas do sub-periodo';
export const PERMISSION_REMOVER_RESERVAS_SUB_PERIODO =
  'Remover reserva de unidade';
export const PERMISSION_RESERVAR_UNIDADE_SUB_PERIODO = 'Reservar unidade';
export const PERMISSION_MENU_GERENCIAR_CADASTRO =
  'Gerenciar Cadastro - Listagem';
export const PERMISSION_MENU_LOGS = 'Listagem de logs';
export const PERMISSION_MENU_APROVACAO_CADASTRO =
  'Aprovação de Cadastro Listagem';
export const PERMISSION_MENU_PERFIL = 'Perfil';
export const PERMISSION_MENU_PESQUISA = 'Pesquisa';
export const PERMISSION_MENU_INSCREVER_ASSOCIADO = 'Inscrever associado';
export const PERMISSION_MENU_RESULTADO_ASSOCIADO = 'Resultado do associado';
export const PERMISSION_MENU_POSTOS = 'Listagem posto e graduação';

export const PERMISSION_MENU_CAMPANHAS = 'Listagem de campanhas';

export const PERMISSION_MENU_INSCRICOES = 'Listagem inscrições';
export const PERMISSION_MENU_RESULTADOS = 'Listagem Resultados';
