import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
    },
  },
  palette: {
    primary: {
      main: '#2e3192',
      dark: '#5261f1', // hover
      contrastText: '#fff',
    },
    secondary: {
      main: '#E36256',
      dark: '#E46456', // hover
      contrastText: '#fff',
    },

    error: {
      main: '#f44336',
    },

    text: {
      secondary: '#717171',
    },

    badge: {
      inativo: { color: '#de7686' },
      historico: { color: '#7b8b99' },
      irregular: { color: '#ea8d49' },
      ativo: { color: '#2cb4a7' },
    },

    status_associado: {
      regular: '#6ABA45',
      irregular: '#FFEB3B',
      nao_socio: '#E74C3C',
    },
  },
});

export default theme;
